<template>
  <Details
    title="location"
    resource="locations"
    data-provider="$raaDataProvider"
    :base-path="basePath"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel entity-title="location" new-entity></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <LocationForm
        :initial-values="initialValues"
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import LocationForm from "@/views/auth/raa-devtools/locations/LocationForm";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import {v4} from "uuid";

  export default {
    name: "LocationCreate",
    components: {LocationForm, Details, DetailsSidePanel},
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      },
      initialValues() {
        return {
          id: v4()
        }
      },
    },
  }
</script>

<style scoped>

</style>
